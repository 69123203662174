<template>
  <div>
    <b-modal ref="warningDialog" title="User Settings Error"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only >
      <span v-html="warningMsg"></span>
    </b-modal>
    <b-modal ref="modalRef" title="User Settings"
      size="xl" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" ok-only
      @close="handleClose" @ok="handleClose">
      <b-tabs v-model="tabIndex" pills vertical @activate-tab="handleActivateTab">
        <b-tab disabled>
          <template #title><strong>Worklist</strong></template>
        </b-tab>
        <b-tab title="‣ Columns (Select)">
          <b-form>
            <div>
              <b-alert variant="info" show>Select column names for worklist.</b-alert>
            </div>
            <b-checkbox-group id="worklistColumns" v-model="worklistColumnsSelected" @change="worklistColumnSelectedChanged" stacked>
              <b-row v-for="field in fieldsAllowed" :key="field.key">
                <b-col>
                  <b-form-checkbox :value="field.key">{{ field.label }}</b-form-checkbox>
                </b-col>
              </b-row>
            </b-checkbox-group>
          </b-form>
        </b-tab>
        <b-tab title="‣ Columns (Order)">
          <div>
            <b-alert variant="info" show>Drag column names to re-order how they appear in worklist.</b-alert>
          </div>
          <div class="slickDiv mt-2 ml-2">
            <SlickList lockAxis="y" v-model="worklistColumnsSorted" tag="div" @input="worklistColumnOrderChanged" appendTo=".slickDiv">
              <SlickItem v-for="(field, index) in worklistColumnsSorted" :index="index" :key="index" tag="div">
                {{field.label}}
              </SlickItem>
            </SlickList>
          </div>
        </b-tab>
        <b-tab title="‣ Actions">
          <b-form>
            <div>
              <b-alert variant="info" show>Select action buttons for worklist.</b-alert>
            </div>
            <b-checkbox-group id="worklistActions" v-model="worklistActionsSelected" @change="worklistActionsSelectedChanged" stacked>
              <div v-for="action in actions" :key="action.key">
                <b-form-checkbox v-if="canUseAction(action.key)" :value="action.key">{{ action.label }}</b-form-checkbox>
              </div>
            </b-checkbox-group>
          </b-form>
        </b-tab>
        <b-tab title="‣ Options">
          <b-form>
            <div>
              <b-alert variant="info" show>Select options for worklist.</b-alert>
            </div>
            <b-form-group label="Select Default Customer:" label-for="defaultCustomer"  label-cols="6">
              <b-form-select id="defaultCustomer" v-model="userGlobalSettings.default_customer_id" :options="customerOptions" @change="userGlobalSettingsChanged">
              </b-form-select>
            </b-form-group>
            <b-form-group label="Auto-Refresh Interval (in minutes):" label-for="refreshInterval"  label-cols="6">
              <b-form-spinbutton id="refreshInterval" v-model="worklistRefreshInterval" min="1" max="30" @change="worklistRefreshIntervalChanged"></b-form-spinbutton>
            </b-form-group>
          </b-form>
        </b-tab>
        <b-tab disabled>
          <template #title><strong>Viewers</strong></template>
        </b-tab>
        <b-tab title="‣ Options" lazy>
          <b-form>
            <div>
              <b-alert variant="info" show>Select options for built-in viewers.</b-alert>
              <b-alert v-if="lockViewerOptions" variant="warning" show>Viewer options can only be updated from main window.</b-alert>
            </div>
            <b-row>
              <b-col cols="6" class="text-right">
                Full Viewer Version:
              </b-col>
              <b-col cols="6">
                <b-form-radio-group id="fullViewerType" v-model="worklistViewers.ohif_version" @change="worklistViewersChanged">
                  <b-form-radio value="V2" :disabled="lockViewerOptions">Stable</b-form-radio>
                  <b-form-radio value="V3" :disabled="lockViewerOptions">Beta</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" class="text-right">
                Open Viewers in:
              </b-col>
              <b-col cols="6">
                <b-form-radio-group id="viewersOpenIn" v-model="worklistViewers.viewers_open_in" @change="worklistViewersChanged">
                  <b-form-radio value="worklistWindow" :disabled="lockViewerOptions">Worklist Window</b-form-radio>
                  <b-form-radio value="viewerWindow" :disabled="lockViewerOptions">Viewer Window</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="6" class="text-right">
                Viewer for Double-Click Action:
              </b-col>
              <b-col cols="6">
                <b-form-radio-group id="dblClickRoute" v-model="worklistViewers.dbl_click_route" @change="worklistViewersChanged">
                  <b-form-radio value="viewer" :disabled="lockViewerOptions">Full</b-form-radio>
                  <b-form-radio value="viewer_lite" :disabled="lockViewerOptions">Lite</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="6" class="text-right">Quality for Lite Viewer:</b-col>
              <b-col cols="6">
                <b-form-spinbutton id="liteQuality" v-model="worklistViewers.lite_quality" min="20" max="100" @change="worklistViewersChanged" :disabled="lockViewerOptions"></b-form-spinbutton>
                <b-form-text id="listQualityHelp">Lower more compression/faster.<br/>Higher less compression/slower.</b-form-text>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="6" class="text-right">Annotation Color for Lite Viewer:</b-col>
              <b-col cols="6">
                <b-form-radio-group id="annotationColor" v-model="worklistViewers.lite_annotation_color" @change="worklistViewersChanged">
                  <b-form-radio value="#85aed3" :disabled="lockViewerOptions" style="color: #85aed3;">Blue</b-form-radio>
                  <b-form-radio value="green" :disabled="lockViewerOptions" style="color: green;">Green</b-form-radio>
                  <b-form-radio value="lime" :disabled="lockViewerOptions" style="color: lime;">Lime</b-form-radio>
                  <b-form-radio value="orange" :disabled="lockViewerOptions" style="color: orange;">Orange</b-form-radio>
                  <b-form-radio value="white" :disabled="lockViewerOptions" style="color: white;">White</b-form-radio>
                  <b-form-radio value="yellow" :disabled="lockViewerOptions" style="color: yellow;">Yellow</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row v-if="!isMedDreamUser" class="mt-2">
              <b-col cols="6" class="text-right">Measurement Units for Full Viewer:</b-col>
              <b-col cols="6">
                <b-form-radio-group id="measurementUnits" v-model="worklistViewers.measurement_units" @change="worklistViewersChanged">
                  <b-form-radio value="cm" :disabled="lockViewerOptions">Centimeters (cm)</b-form-radio>
                  <b-form-radio value="mm" :disabled="lockViewerOptions">Millimeters (mm)</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row v-if="!isMedDreamUser" class="mt-2">
              <b-col cols="6" class="text-right">Cache size for Full Viewer:</b-col>
              <b-col cols="6">
                <b-form-spinbutton id="cacheSize" v-model="worklistViewers.cache_size" min="0.5" max="16" step="0.25" @change="worklistViewersChanged" :disabled="lockViewerOptions"></b-form-spinbutton>
                <b-form-text id="cacheSizeHelp">Size in GB.<br/><b-icon icon="exclamation-triangle-fill"></b-icon> High values may lead to performance issues.</b-form-text>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <b-tab title="‣ Alternative" lazy>
          <b-form>
            <div>
              <b-alert variant="info" show>Select alternative viewers to launch (must be installed).<br/>
                <b-badge variant="warning" show>Some configuration may be required in alternative viewer for it to be able to access image data.</b-badge>
              </b-alert>
            </div>
            <b-checkbox-group v-if="canViewImages" id="worklistAltViewers" v-model="worklistAltViewersSelected" @change="worklistAltViewersSelectedChanged" stacked>
              <template v-for="viewer in viewers">
                <b-form-checkbox v-if="viewer.enabled" :key="viewer.key" :value="viewer.key">
                  {{ viewer.label }}
                </b-form-checkbox>
                <template v-for="help in viewer.help">
                  <b-form-text v-if="viewer.enabled" :key="help.key">{{ help.text }}
                    <a :href="help.url" target="_blank">{{ help.url }}</a>
                  </b-form-text>    
                </template>
              </template>
            </b-checkbox-group>
          </b-form>
        </b-tab>
        <b-tab title="‣ Window/Level" lazy>
          <b-form>
            <div>
              <b-alert variant="info" show>Predefined window-level settings for built-in viewers.
                <b-button variant="secondary" title="Add New" @click="wwwlFavoritesUseDefaults" size="sm">Use Defaults</b-button>
              </b-alert>
            </div>
            <b-form-row>
              <b-col cols="5">
                Description
              </b-col>
              <b-col cols="3">
                Window Width
              </b-col>
              <b-col cols="3">
                Window Level
              </b-col>
              <b-col cols="1">
                <b-button variant="secondary" title="Add New" @click="wwwlFavoriteAdd"><b-icon icon="plus-circle"></b-icon></b-button>
              </b-col>
            </b-form-row>
            <b-form-row v-for="key in wwwlFavoritesKeys" class="mt-1" :key="key">
              <b-col cols="5">
                <b-form-input v-model="wwwlFavoritesDescriptions[key]" @change="wwwlFavoriteChanged"/>
              </b-col>
              <b-col cols="3">
                <b-form-input v-model="wwwlFavoritesWindows[key]" type="number" @change="wwwlFavoriteChanged"/>
              </b-col>
              <b-col cols="3">
                <b-form-input v-model="wwwlFavoritesLevels[key]" type="number" @change="wwwlFavoriteChanged"/>
              </b-col>
              <b-col cols="1">
                <b-button variant="secondary" title="Delete" @click="wwwlFavoriteDelete(key)"><b-icon icon="trash"></b-icon></b-button>
              </b-col>
            </b-form-row>
          </b-form>
        </b-tab>
        <b-tab disabled>
          <template #title><strong>Reports</strong></template>
        </b-tab>
        <b-tab title="‣ Options" lazy>
          <b-form>
            <div>
              <b-alert variant="info" show>Select options for reports.</b-alert>
            </div>
            <b-row>
              <b-col cols="3">
                Open reports in:
              </b-col>
              <b-col cols="9">
                <b-form-radio-group id="reportsOpenIn" v-model="worklistViewers.reports_open_in" @change="worklistViewersChanged">
                  <b-form-radio value="sidePanel">Side Panel</b-form-radio>
                  <b-form-radio value="window">Report Window</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row v-if="canEditReports" class="mt-5">
              <b-col cols="3">
                Auto-create Report:
              </b-col>
              <b-col cols="9">
                <b-form-radio-group id="autoCreateReport" v-model="reportSettings.auto_create" @change="reportSettingsChanged">
                  <b-form-radio :value="true">On</b-form-radio>
                  <b-form-radio :value="false">Off</b-form-radio>
                </b-form-radio-group>
                <b-form-text>If enabled and a study has no reports, a draft report based on default template will be created when Report Viewer is opened.</b-form-text>
              </b-col>
            </b-row>
            <b-row v-if="canEditReports" class="mt-5">
              <b-col cols="3">
                Auto-save Interval (in minutes):
              </b-col>
              <b-col cols="9"><b-form-spinbutton id="autoSaveInterval" v-model="reportSettings.auto_save" min="1" max="30" @change="reportSettingsChanged"></b-form-spinbutton>
                <b-form-text>Interval to auto-save report when opened in Report Editor.</b-form-text>
              </b-col>
            </b-row>
            <b-row v-if="canSignReports" class="mt-5">
              <b-col cols="3">
                Current signature:
              </b-col>
              <b-col cols="9">
                <div v-if="signatureImageSrc == null">No signature image for current user.</div>
                <b-img v-if="signatureImageSrc != null" v-bind="signatureImageProps" :src="signatureImageSrc" fluid rounded alt="Signature"></b-img>
              </b-col>
            </b-row>
            <b-row v-if="canSignReports" class="mt-2">
              <b-col>
                <b-form-group label="New Signature:" label-for="signature_image_file"  label-cols="3">
                  <b-form-file
                    v-model="signatureImageFile"
                    id="signature_image_file"
                    :state="Boolean(signatureImageFile)"
                    accept="image/jpeg, image/png, image/gif"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."/>
                    <b-form-text>File size limited to {{uploadLimitMb}} MB.</b-form-text>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="canSignReports" class="mt-2">
              <b-col>
                <div class="float-right">
                  <b-button @click="uploadSignature" :disabled="(signatureImageFile == null) || (signatureImageFile.size > uploadLimitMb*1024*1024)" variant="primary">Upload New Signature</b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <b-tab title="‣ Auto-Correct" lazy>
          <b-form>
            <div>
              <b-alert variant="info" show>Auto-correct options for reports.
              </b-alert>
            </div>
            <b-form-checkbox v-model="reportSettings.capitalize_sentences" @change="reportSettingsChanged">
              Capitalize first letter of sentences
            </b-form-checkbox>
          </b-form>
        </b-tab>
        <b-tab title="‣ Auto-Replacements" lazy>
          <b-form>
            <div>
              <b-alert variant="info" show>Predefined automatic replacements for phrases.<br/>
                <b-badge variant="warning">Phrases may only contain alphanumeric characters, no spaces or punctuation.</b-badge>&nbsp;
                <b-badge variant="warning">Phrase matching is case-senstive.</b-badge>
              </b-alert>
            </div>
            <b-form-row>
              <b-col cols="3">
                Phrase
              </b-col>
              <b-col cols="8">
                Replacement
              </b-col>
              <b-col cols="1">
                <b-button variant="secondary" title="Add New" @click="reportPhraseAdd"><b-icon icon="plus-circle"></b-icon></b-button>
              </b-col>
            </b-form-row>
            <b-form-row v-for="key in reportPhraseKeys" class="mt-1" :key="key">
              <b-col cols="3">
                <b-form-input v-model="reportPhrasePhrases[key]" @change="reportPhraseChanged"/>
              </b-col>
              <b-col cols="8">
                <b-form-textarea v-model="reportPhraseReplacements[key]" rows="2" @change="reportPhraseChanged"/>
              </b-col>
              <b-col cols="1">
                <b-button variant="secondary" title="Delete" @click="reportPhraseDelete(key)"><b-icon icon="trash"></b-icon></b-button>
              </b-col>
            </b-form-row>
          </b-form>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import permissions from '../common/permissions'
import worklistActions from '../common/worklistActions.json'
import worklistFields from '../common/worklistFields.json'
import worklistAltViewers from '../common/worklistAltViewers.json'
import webServices from '../common/webServices'
import wwwlDefaults from '../common/wwwlDefaults.json'
import { SlickList, SlickItem } from 'vue-slicksort'
import uuid from "uuid"

export default {
  name: 'userSettings',
  components: {
    SlickItem,
    SlickList
  },
  data() {
    return {
      actions: worklistActions.data,
      viewers: worklistAltViewers.data,
      worklistActionsSelected: [],
      worklistAltViewersSelected: [],
      worklistColumnsAllowed: [],
      worklistColumnsSorted: [],
      worklistColumnsSelected: [],
      worklistRefreshInterval: 5,
      worklistViewers: {
        "dbl_click_route": "viewer",
        "lite_quality": 70,
        "lite_annotation_color": "#85aed3",
        "measurement_units": "mm",
        "reports_open_in": "sidePanel",
        "viewers_open_in": "worklistWindow",
        "cache_size": 2,
        "ohif_version": "V2",
      },
      worklistOhifV3Selected:false,
      signatureImageSrc: null,
      signatureImageFile: null,
      signatureImageProps: { blank: false, class: 'm1' },
      speechToTextOptIn: false,
      refreshWorklistOnClose: false,
      wwwlFavoritesKeys: [],
      wwwlFavoritesDescriptions: {},
      wwwlFavoritesWindows: {},
      wwwlFavoritesLevels: {},
      wwwlFavoritesDirty: false,
      reportPhraseKeys: [],
      reportPhrasePhrases: {},
      reportPhraseReplacements: {},
      reportPhraseDirty: false,
      reportSettings: {
        "auto_create": false,
        "auto_save": 5,
        "capitalize_sentences": false,
      },
      userGlobalSettings: {
        default_customer_id: '',
      },
      tabIndex: 0,
      warningMsg: ""
    }
  },
  props: {
    "lockViewerOptions": Boolean,
  },
  computed: {
    canEditReports() {
      if ((permissions.groupsForPermission(permissions.CAN_EDIT_REPORTS).length > 0)) {
        return true
      }
      return false
    },
    canSignReports() {
      if ((permissions.groupsForPermission(permissions.CAN_SIGN_PRELIM).length > 0) || (permissions.groupsForPermission(permissions.CAN_SIGN_FINAL).length > 0)) {
        return true
      }
      return false
    },
    canViewImages() {
      if ((permissions.groupsForPermission(permissions.CAN_VIEW_IMAGES).length > 0)) {
        return true
      }
      return false
    },
    customerOptions() {
      var options = [{
        value: '',
        text: 'No Default Selected',
        disabled: false
      }]
      const customers = this.$store.state.customers
      const customerIds = Object.keys(customers)
      for (var i=0; i < customerIds.length; i++) {
        let customerId = customerIds[i]
        options.push({
          value: customerId,
          text: customers[customerId].name,
          disabled: false
        })
      }
      return options
    },
    fieldsAllowed() {
      var fields = []
      worklistFields.data.forEach(field => {
        if (this.worklistColumnsAllowed.includes(field.key)) {
          fields.push(field)
        }
      })
      return fields
    },
    isMedDreamUser() {
      return permissions.isMedDreamUser()
    },
    reportPhraseLut() {
      return this.$store.state.reportPhraseLut
    },
    uploadLimitMb() {
      return this.$store.state.uploadLimitMb;
    },
    wwwlFavorites() {
      return this.$store.state.wwwlFavorites
    }
  },
  methods: {
    canUseAction(key) {
      var canUseAction = false
      const action = this.actions.find(o => o.key === key)
      if (action) {
        action.permissions.forEach(permission => {
          if (permissions.groupsForPermission(permission).length > 0) {
            canUseAction = true
          }
        })
      }
      return canUseAction
    },
    initWorklistColumnsSelected() {
      this.worklistColumnsSelected = []
      for (var i=0; i<this.worklistColumnsSorted.length; i++) {
        this.worklistColumnsSelected.push(this.worklistColumnsSorted[i].key)
      }
      this.$log.debug(this.worklistColumnsSelected)
    },
    initWorklistColumnsSorted() {
      this.worklistColumnsSorted = []
      for (var i=0; i<this.worklistColumnsSelected.length; i++) {
        for (var j=0; j<worklistFields.data.length; j++) {
          const field = worklistFields.data[j]
          if ((field.key == this.worklistColumnsSelected[i]) && (this.worklistColumnsAllowed.includes(field.key))) {
            this.worklistColumnsSorted.push(field)
            break;
          }
        }
      }
      this.$log.debug(this.worklistColumnsSorted)
    },
    show() {
      this.refreshWorklistOnClose = false
      this.worklistActionsSelected = this.$store.state.worklistActions
      this.worklistColumnsAllowed = []  // default is allow all if adminSettings missing or empty
      webServices.readUserSetting("adminSettings")
      .then(response => {
        if ((response != null) && (response.worklistColumnsAllowed != undefined) && Array.isArray(response.worklistColumnsAllowed)) {
          // Empty array is equivalent to allowing all columns.
          //
          this.worklistColumnsAllowed = response.worklistColumnsAllowed
        }
      })
      .catch(err => {
        this.$log.error("Error fetching worklistColumnsAllowed setting: "+err)
      })
      .finally(() => {
        if (this.worklistColumnsAllowed.length == 0) {
          worklistFields.data.forEach(field => {
            this.$log.debug(`Adding ${field.key} to worklistColumnsAllowed`)
            this.worklistColumnsAllowed.push(field.key)
          })
        }
        this.initWorklistColumnsSorted()
      })
      this.worklistColumnsSelected = this.$store.state.worklistColumns
      this.worklistAltViewersSelected = this.$store.state.worklistAltViewers
      this.worklistRefreshInterval = this.$store.state.worklistRefreshInterval
      var keys = Object.keys(this.$store.state.worklistViewers)
      var k = 0
      for (k=0; k < keys.length; k++) {
        this.worklistViewers[keys[k]] = this.$store.state.worklistViewers[keys[k]]
      }
      this.wwwlFavoritesKeys = []
      this.wwwlFavoritesDescriptions = {}
      this.wwwlFavoritesWindows = {}
      this.wwwlFavoritesLevels = {}
      for (var f=0; f < this.wwwlFavorites.length; f++) {
        const key = this.wwwlFavorites[f].key
        this.wwwlFavoritesKeys.push(key)
        this.wwwlFavoritesDescriptions[key] = this.wwwlFavorites[f].description
        this.wwwlFavoritesWindows[key] = this.wwwlFavorites[f].window
        this.wwwlFavoritesLevels[key] = this.wwwlFavorites[f].level
      }
      this.wwwlFavoritesDirty = false
      this.reportPhraseKeys = []
      this.reportPhrasePhrases = {}
      this.reportPhraseReplacements = {}
      let phrases = Object.keys(this.reportPhraseLut)
      for (var p=0; p < phrases.length; p++) {
        let key = uuid.v4()
        this.reportPhraseKeys.push(key)
        this.reportPhrasePhrases[key] = phrases[p]
        this.reportPhraseReplacements[key] = this.reportPhraseLut[phrases[p]]
      }
      this.reportPhraseDirty = false
      this.reportSettings = {...this.reportSettings, ...this.$store.state.reportSettings}
      this.userGlobalSettings = {...this.userGlobalSettings, ...this.$store.state.userGlobalSettings}
      this.$refs['modalRef'].show()
      this.readSignatureImage()
    },
    handleClose() {
      this.$log.debug(`wwwlFavoritesDirty=${this.wwwlFavoritesDirty}`)
      if (this.wwwlFavoritesDirty) {
        var wwwlFavorites = []
        for (var f=0; f < this.wwwlFavoritesKeys.length; f++) {
          const key = this.wwwlFavoritesKeys[f]
          const window = parseFloat(this.wwwlFavoritesWindows[key])
          const level = parseFloat(this.wwwlFavoritesLevels[key])
          wwwlFavorites.push({
            key: key,
            description: this.wwwlFavoritesDescriptions[key],
            window: isNaN(window) ? 1 : window,
            level: isNaN(level) ? 0 : level,
          })
        }
        this.$log.debug("Updating cache for wwwlFavorites")
        webServices.updateUserSetting("wwwlFavorites", wwwlFavorites)
        .then(response => {
          this.$log.debug(response.data)
        })
        .catch(err => {
          this.$log.error("Error updating cached settings: "+err)
        })
        .finally(() => {
          this.$store.commit("changeWwwlFavorites", wwwlFavorites)
        })
      }

      this.warningMsg = ""
      this.$log.debug(`reportPhraseDirty=${this.reportPhraseDirty}`)
      if (this.reportPhraseDirty) {
        var reportPhraseLut = {}
        for (var p=0; p < this.reportPhraseKeys.length; p++) {
          const key = this.reportPhraseKeys[p]
          const phrase = this.reportPhrasePhrases[key].trim()
          if (/^[a-zA-Z0-9]+$/i.test(phrase) == false) {
            this.warningMsg += `Phrase=[${phrase}]: phrases must only contain alphanumeric characters.<br/>`
          }
          if (phrase in reportPhraseLut) {
            this.warningMsg += `Phrase=[${phrase}]: phrases should only be used once.<br/>`
          }
          const replacement = this.reportPhraseReplacements[key]
          if ((phrase.length > 0) && (replacement.length > 0)) {
            reportPhraseLut[phrase] = replacement
          }
        }
        
        if (this.warningMsg == "") {
          this.$log.debug("Updating cache for reportPhraseLut")
          webServices.updateUserSetting("reportPhraseLut", reportPhraseLut)
          .then(response => {
            this.$log.debug(response.data)
          })
          .catch(err => {
            this.$log.error("Error updating cached settings: "+err)
          })
          .finally(() => {
            this.$store.commit("changeReportPhraseLut", reportPhraseLut)
          })
        }
        else {
          this.warningMsg = "<strong>Reports: Auto-Replacements</strong><br/>" + this.warningMsg
          this.$refs.warningDialog.show()
          return
        }
      }

      this.$log.debug(`refreshWorklistOnClose=${this.refreshWorklistOnClose}`)
      if (this.refreshWorklistOnClose) {
        webServices.readWorklist()
      }
    },
    handleActivateTab() {
      this.$refs['modalRef'].$refs.body.scrollTop = 0;
    },
    handleWarningOK() {
      // Keep same values, let user fix.
      //
      this.$refs['modalRef'].show()
    },
    readSignatureImage() {
      // Load image for signature
      //
      webServices.readSignature()
      .then((response) => {
        const blob = new Blob([response])
        this.signatureImageSrc = URL.createObjectURL(blob);
      })
      .catch(err => {
        this.$log.error("Error fetching signature: "+err)
        this.signatureImageSrc = null
      })
    },
    userGlobalSettingsChanged() {
      webServices.updateUserGlobalSettings(this.userGlobalSettings)
      .then(() => {
        this.$log.debug('Updated user global settings.')
      })
      .catch(err => {
        this.$log.error("Error updating cached settings: "+err)
      })
      .finally(() => {
        this.$store.commit("changeUserGlobalSettings", this.userGlobalSettings)
      })
    },
    worklistViewersChanged() {
      this.$log.debug("Updating cache for worklistViewers")
      webServices.updateUserSetting("worklistViewers", this.worklistViewers)
      .then(response => {
        this.$log.debug(response.data)
      })
      .catch(err => {
        this.$log.error("Error updating cached settings: "+err)
      })
      .finally(() => {
        this.initWorklistColumnsSorted()
        this.$store.commit("changeWorklistViewers", this.worklistViewers)
      })
    },
    worklistColumnOrderChanged() {
      this.initWorklistColumnsSelected()
      this.$log.debug("Updating cache for worklistColumns")
      webServices.updateUserSetting("worklistColumns", this.worklistColumnsSelected)
      .then(response => {
        this.$log.debug(response.data)
      })
      .catch(err => {
        this.$log.error("Error updating cached settings: "+err)
      })
      .finally(() => {
        this.$store.commit("changeWorklistColumns", this.worklistColumnsSelected)
      })
    },
    worklistColumnSelectedChanged() {
      this.$log.debug("Updating cache for worklistColumns")
      webServices.updateUserSetting("worklistColumns", this.worklistColumnsSelected)
      .then(response => {
        this.$log.debug(response.data)
      })
      .catch(err => {
        this.$log.error("Error updating cached settings: "+err)
      })
      .finally(() => {
        this.initWorklistColumnsSorted()
        this.$store.commit("changeWorklistColumns", this.worklistColumnsSelected)
      })
    },
    worklistActionsSelectedChanged() {
      this.$log.debug("Updating cache for worklistActions")
      webServices.updateUserSetting("worklistActions", this.worklistActionsSelected)
      .then(response => {
        this.$log.debug(response.data)
      })
      .catch(err => {
        this.$log.error("Error updating cached settings: "+err)
      })
      .finally(() => {
        this.$store.commit("changeWorklistActions", this.worklistActionsSelected)
      })
    },
    worklistAltViewersSelectedChanged() {
      this.$log.debug("Updating cache for worklistAltViewers")
      webServices.updateUserSetting("worklistAltViewers", this.worklistAltViewersSelected)
      .then(response => {
        this.$log.debug(response.data)
      })
      .catch(err => {
        this.$log.error("Error updating cached settings: "+err)
      })
      .finally(() => {
        this.initWorklistColumnsSorted()
        this.$store.commit("changeWorklistAltViewers", this.worklistAltViewersSelected)
      })
    },
    worklistRefreshIntervalChanged() {
      this.$log.debug("Updating cache for worklistRefreshInterval")
      webServices.updateUserSetting("worklistRefreshInterval", this.worklistRefreshInterval)
      .then(response => {
        this.$log.debug(response.data)
      })
      .catch(err => {
        this.$log.error("Error updating cached settings: "+err)
      })
      .finally(() => {
        this.$store.commit("changeWorklistRefreshInterval", this.worklistRefreshInterval)
      })
    },
    wwwlFavoriteAdd() {
      const key = uuid.v4()
      this.wwwlFavoritesDescriptions[key] = "New"
      this.wwwlFavoritesWindows[key] = 1000
      this.wwwlFavoritesLevels[key] = 500
      this.wwwlFavoritesKeys.push(key)
      this.wwwlFavoritesDirty = true
    },
    wwwlFavoriteChanged(/*value*/) {
      this.wwwlFavoritesDirty = true
    },
    wwwlFavoriteDelete(key) {
      const index = this.wwwlFavoritesKeys.indexOf(key)
      if (index > -1) {
        this.wwwlFavoritesKeys = this.wwwlFavoritesKeys.filter(value => {
          return value != key;
        })
        delete this.wwwlFavoritesDescriptions[key]
        delete this.wwwlFavoritesWindows[key]
        delete this.wwwlFavoritesLevels[key]
        this.wwwlFavoritesDirty = true
      }
    },
    wwwlFavoritesUseDefaults() {
      this.wwwlFavoritesKeys = []
      this.wwwlFavoritesDescriptions = {}
      this.wwwlFavoritesWindows = {}
      this.wwwlFavoritesLevels = {}
      const wwwlFavorites = wwwlDefaults.data
      for (var f=0; f < wwwlFavorites.length; f++) {
        const key = wwwlFavorites[f].key
        this.wwwlFavoritesKeys.push(key)
        this.wwwlFavoritesDescriptions[key] = wwwlFavorites[f].description
        this.wwwlFavoritesWindows[key] = wwwlFavorites[f].window
        this.wwwlFavoritesLevels[key] = wwwlFavorites[f].level
      }
      this.wwwlFavoritesDirty = true
    },
    reportPhraseAdd() {
      const key = uuid.v4()
      this.reportPhrasePhrases[key] = ""
      this.reportPhraseReplacements[key] = ""
      this.reportPhraseKeys.push(key)
      this.reportPhraseDirty = true
    },
    reportPhraseChanged() {
      this.reportPhraseDirty = true
    },
    reportPhraseDelete(key) {
      const index = this.reportPhraseKeys.indexOf(key)
      if (index > -1) {
        this.reportPhraseKeys = this.reportPhraseKeys.filter(value => {
          return value != key;
        })
        delete this.reportPhrasePhrases[key]
        delete this.reportPhraseReplacements[key]
        this.reportPhraseDirty = true
      }
    },
    reportSettingsChanged() {
      webServices.updateUserSetting("reportSettings", this.reportSettings)
      .then(response => {
        this.$log.debug(response.data)
      })
      .catch(err => {
        this.$log.error("Error updating cached settings: "+err)
      })
      .finally(() => {
        this.initWorklistColumnsSorted()
        this.$store.commit("changeReportSettings", this.reportSettings)
      })
    },
    uploadSignature() {
      var signatureImageFileName = this.signatureImageFile.name
      var reader = new FileReader();
      var thisForReader = this
      reader.onerror = function(/*file*/) {
        Vue.$log.error("Error reading signature image file="+signatureImageFileName)
        reader.abort();
      };

      reader.onload = function(/*file*/) {
        Vue.$log.debug("Read signature image file="+signatureImageFileName)
        webServices.updateSignature(new Blob([reader.result]), signatureImageFileName)
        .then(response => {
          Vue.$log.debug("Updated signature with image file="+signatureImageFileName)
          Vue.$log.debug(response)
          thisForReader.readSignatureImage()
          thisForReader.signatureImageFile = null
        })
        .catch(err => {
          Vue.$log.error("Error updating signature image: "+err.message)
        })
      }
      reader.readAsArrayBuffer(this.signatureImageFile);
    }
  }
};
</script>
<style scoped>
</style>